<template>
  <b-container class="full-width no-padding-lr">
    <ContactStats />
    <VideoGuide />
    <b-row v-if="isAccountSetupFinished === false">
      <b-col>
        <template>
          <b-row>
            <b-col>
              <b-card>
                <b-card-header>
                  <b-card-title>Finish setting up your account</b-card-title>
                </b-card-header>

                <b-card-body>
                  <SetupItemStatus
                    v-for="(accountSetupItem, index) in accountSetupItems"
                    :key="accountSetupItem.index"
                    :title="accountSetupItem.title"
                    :item-number="index + 1"
                    :status="accountSetupItem.status"
                    :route-name="accountSetupItem.routeName"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <template v-if="upcomingServices && upcomingServices.length">
          <b-row>
            <b-col>
              <b-card>
                <b-card-header>
                  <b-card-title>Upcoming Services</b-card-title>
                </b-card-header>

                <b-card-body>
                  <b-list-group>
                    <template>
                      <b-list-group-item
                        v-for="service in upcomingServices"
                        :key="service.id"
                        class="d-flex"
                      >
                        <div class="services-scheduled-visits-elements-left">
                          <div
                            class="d-flex flex-row align-items-center services-scheduled-date-col"
                          >
                            <span
                              class="services-scheduled-col"
                            >
                              <span>
                                <feather-icon
                                  class="services-scheduled-icon"
                                  icon="CalendarIcon"
                                  size="16"
                                />
                              </span>
                              <span>
                                {{ service.date|formatDateReturnDayAndMonthOnly }}
                              </span>
                            </span>
                            <span class="services-scheduled-col">
                              <feather-icon
                                class="services-scheduled-icon"
                                icon="ClockIcon"
                                size="16"
                              />
                              <span>
                                {{ service.time|formatTime }}
                              </span>
                            </span>
                          </div>
                          <div
                            class="d-flex flex-row align-items-center services-scheduled-name"
                          >
                            <div>
                              <span>
                                Service name: {{ service.name }} <span v-if="service.visitor_count"> | </span>
                                <template v-if="service.visitor_count">
                                  <router-link
                                    :to="{name:'contacts', query: {q: service.date + ' ' + service.time}}"
                                  >
                                    <span>
                                      <strong>{{ service.visitor_count }} visit{{ service.visitor_count > 1 ? 's' : '' }} scheduled</strong>
                                    </span>
                                  </router-link>
                                </template>
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <template v-if="isLoadingUpcomingServices">
          <b-card>
            <b-spinner
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              label="Large Spinner"
              variant="primary"
            />
          </b-card>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BContainer, BRow, BSpinner, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import SetupItemStatus from '@/views/pages/dashboard/SetupItemStatus.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ContactStats from '@/views/components/contacts/ContactStats.vue'
import AccountService from '@/services/AccountService'
import VideoGuide from '@/views/pages/dashboard/VideoGuide.vue'

export default {
  components: {
    ContactStats,
    BCard,
    BContainer,
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BSpinner,
    BListGroup,
    BListGroupItem,
    SetupItemStatus,
    VideoGuide,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      upcomingServices: null,
      accountSetupItems: null,
      isAccountSetupFinished: null,
      isFirstContactCreated: false,
      isLoadingUpcomingServices: true,
    }
  },
  async created() {
    const self = this
    if (!store.getters['contacts/contactList'] && !store.getters['contacts/loading']) {
      await store.dispatch('contacts/loadContactList').then(() => {
        self.updateIsFirstContactCreated()
      })
    } else {
      self.updateIsFirstContactCreated()
    }

    self.initializeAccountSetupCard()

    AccountService.getUpcomingServices()
      .then(response => {
        self.upcomingServices = response.data.data
        self.isLoadingUpcomingServices = false
      })
  },
  methods: {
    kFormatter(num) {
      return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num)
    },
    initializeAccountSetupCard() {
      // NOTE: to determine if account setup is complete we need to wait for contact list to be loaded
      // isAccountSetupFinished is null until we gather all info and then set it to true or false
      const { account } = store.getters['auth/authUser']
      const isAccountSet = account.settings.general.church_name !== ''
      const isCampusCreated = account.locations.length > 0
      let isServiceCreated = false
      account.locations.forEach(location => {
        if (location.recurring_events.length > 0 || location.single_events.length > 0) {
          isServiceCreated = true
        }
      })

      this.isAccountSetupFinished = isAccountSet && isCampusCreated && isServiceCreated && this.isFirstContactCreated

      if (!this.isAccountSetupFinished) {
        // TODO: for some reason route name don't work in the component
        // and are rendered as regular urls. Change key names to url or better
        // make it use route names to generate urls.
        this.accountSetupItems = [
          {
            title: 'Complete your account details',
            status: isAccountSet,
            routeName: 'account',
          },
          {
            title: 'Add a campus',
            status: isCampusCreated,
            routeName: 'campuses/new',
          },
          {
            title: 'Create your first service',
            status: isServiceCreated,
            routeName: 'campuses',
          },
          {
            title: 'Install the code on your site so guests can plan their visit!',
            status: this.isFirstContactCreated,
            routeName: 'installation',
          },
        ]
      }
    },
    updateIsFirstContactCreated() {
      this.isFirstContactCreated = store.getters['contacts/contactCount'] > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.container.full-width {
  max-width: 100%;
}

.no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}

.truncate {
  width: 100%;
  margin: 0.75rem 0 0;
}

.stat-holder {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.truncate.text-left {
  padding-left: 25px;
  width: auto;
}

.b-avatar-lg {
  width: 4.5rem;
  height: 4.5rem;
}

.visited_counter_icon,
.scheduled_counter_icon,
.did_not_attend_icon {
  width: 2rem;
  height: 2rem;
}

.apexcharts-legend.apexcharts-align-center.position-left {
  left: 0 !important;
}
no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}
.small-padding {
  padding: 0.75rem;
}

.small-margin-bottom {
  margin-bottom: 0.75rem;
}

.container.full-width {
  max-width: 100%;
}
.top-pagination-col ul {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.bottom-pagination-col ul {
  justify-content: center;
}
.contact-list-card {
  margin-bottom: 2rem;
}

.contact-list-table td {
  padding: 0 5px;
}

.td-wrap .custom-select {
  max-width: 190px;
}
.inverted-badge {
  background: transparent;
  border: 1px solid var(--purple);
  padding: 0.75rem;
  color: var(--purple);
}

.no-padding {
  padding: 0;
}
.service-day-column{
  width:calc(50% - 20px);
}
.service-time-column{
  width: calc(50% + 20px);
}
.service-time-column > span{
  margin-right:10px;
}
.service-scheduled-visits-col{
  width:50%;
}
.service-scheduled-visits-holder{
  display: flex;
  flex-flow: row;
  width:100%;
  justify-content: flex-end;
  align-items: center;
}
.service-scheduled-visits-holder > span{
  margin-right: 0.85rem;
}
.service-scheduled-visits-row{
  display:flex;
  flex-flow:column;
}
.service-scheduled-name-row{
  width:100%;
  display:flex;
  flex-flow:column;
  justify-content: center;
  align-items: flex-start;
}
.service-scheduled-card-body{
  width:100%;
  display:flex;
}
.service-scheduled-card-body--row{
  display:flex;
  flex-flow:row wrap;
  width: 100%;
}
.service-scheduled-card-body--col{
  display:flex;
}
.service-scheduled-card-body--col.col-50{
  width:50%;
}
.service-scheduled-card-header{
  padding:1rem;
  color:#FFF;
}
.service-scheduled-card-header h4{
  margin-bottom:0.85rem;
}
.service-scheduled-card-header h4,
.service-scheduled-card-header h5{
  color: #7366f0;
}
.service-scheduled-card .card-body{
  padding:0;
}
.service-scheduled-card-body{
  padding:1.5rem;
}

.services-scheduled-date-col{
  width: 20%;
  min-width: 240px;
}
.services-scheduled-col {
  margin-right: 1rem;
}
.services-scheduled-icon {
  margin-right: 0.5rem;
  margin-top: -4px;
}
.services-scheduled-visitors-col{
  width:20%;
  min-width: 250px;
  @media screen and (max-width: 1400px){
    min-width:initial;
    width: auto;
  }
  }
.services-scheduled-name{

}
.services-scheduled-name > div{
  display:flex;
  flex-flow:row;
}
.services-scheduled-name > div > span{
  margin-right:0.85rem;
}
.services-scheduled-visits-elements-left{
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 991px){
    width:100%;
    flex-flow: column;
    align-items: flex-start;
    & > .d-flex{
      margin-bottom:0.85rem;
    }
  }
}
.list-group-item{
  min-height:57px;
  @media screen and (max-width: 991px){
    flex-flow:column;
    .b-avatar{
      width:2rem;
      height:2rem;
    }
  }
  @media screen and (min-width: 991px) and (max-width: 1440px){
    //justify-content: space-between;

  }
}
</style>
