<template>
  <div class="transaction-item">
    <div :class="['media']">
      <div class="media-aside align-self-start">
        <span
          :class="['b-avatar', status ? 'badge-light-success':'badge-light-danger', 'rounded']"
          style="width: 42px; height: 42px;"
        >
          <span v-if="status">
            <feather-icon
              icon="CheckCircleIcon"
              size="24"
              class="success"
            />
          </span>
          <span v-else>
            <feather-icon
              icon="CircleIcon"
              size="24"
              class="success"
            />
          </span>
        </span>
      </div>
      <div class="media-body">
        <b-link :to="routeName">
          <h6
            v-if="status"
            class="transaction-title"
          >
            <del>{{ itemNumber }}. {{ title }}</del>
          </h6>
          <h6
            v-else
            class="transaction-title"
          >
            {{ itemNumber }}. {{ title }}
          </h6>
          <small>
            {{ description }}
          </small>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: ['title', 'status', 'description', 'routeName', 'itemNumber'],
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
.media{
  display:flex;
  align-items:center;
  margin-bottom:0.5rem;
}
.media-body{
  display:flex;
  align-items: center;
}
.media-body h6{
  margin-bottom:0;
}
</style>
