<template>
  <b-row>
    <b-col>
      <b-card class="text-center">
        <b-row>
          <b-col>
            <app-collapse>
              <app-collapse-item
                title="Click here to see a video walkthrough"
                :is-visible="$store.state['userConfig'].showVideoGuide"
                @visible="collapseToggled"
              >
                <b-card class="mb-6">
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    src="https://www.youtube.com/embed/wXoeyWXMcBU"
                    allowfullscreen
                  />
                </b-card>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BEmbed,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BEmbed,
    AppCollapse,
    AppCollapseItem,
  },
  methods: {
    collapseToggled(val) {
      this.$store.commit('userConfig/setVariable', { name: 'showVideoGuide', value: val })
    },
  },
}
</script>
<style scoped>
.card {
  min-width: 175px;
}
</style>
